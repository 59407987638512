<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 px-5">
          <h2 class="doc-title">Disclaimer</h2>
          <p class="doc-pera">
            Any and all information on our Website does not constitute and should not be considered
as advice or as an offer to sell or as a solicitation of an offer to acquire any Fuse G
Tokens to any person in any jurisdiction.
          </p>
          <p class="doc-pera">
Any and all information on our Website has been provided to you for information
purposes only and may not be relied upon by you in evaluating the merits of acquiring
the Fuse G Tokens. Any references to the Fuse G Token’s past valuation are not a guide
to future performance, or a reliable indicator of future valuation or performance.</p>
          <p class="doc-pera">
The terms of acquiring any Fuse G Tokens can be found in (i) the Whitepaper and (ii) the
terms and conditions relating to the Fuse G Tokens, referred to in the Whitepaper (and
which need to be read in conjunction with the Whitepaper). Any person acquiring Fuse G
Tokens must understand the risks involved.</p>
          <p class="doc-pera">
Distribution of information or documents contained on our Website may be restricted by
law. Accordingly, this information and documents may not be distributed in any
jurisdiction, except under circumstances that will result in compliance with any applicable
laws and regulations. Persons receiving this communication should inform themselves
about and observe any such restrictions. Any dissemination or other unauthorised use of
this information or documents by any person or entity is strictly prohibited.</p>
          <p class="doc-pera">
Prospective purchasers of Fuse G Tokens should consider the risks contained in each of
the Whitepaper and the terms and conditions.</p>
          <p class="doc-pera">
Information contained on our Website is subject to modification, supplementation and
amendment at any time and from time to time.</p>
          <p class="doc-pera">
We assume no responsibility or liability for the correctness, accuracy, timeliness or
completeness of the Information contained on our Website or for any loss, damage or
lost opportunities resulting from the use of the information. Any views, opinions or
assumptions may be subject to change without notice.</p>
          


        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   },
    name: "WebsiteDisclaimer",
}
</script>

<style>

</style>